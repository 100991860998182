import { Typography, Box, Theme, Tooltip, Button } from "@mui/material";
import { CredentialsFormContainer } from "../../components/styled";
import { StandardTextInput } from "../../components/UI";
import { RoundedButton, TextButton } from "../../components/styled";
import { PasswdVisibility } from "../../components/UI/PasswdVisibility";

interface CredentialsFormProps {
    currentView: 'credentials' | 'mfa' | 'complete';
    username: string;
    password: string;
    usernameError: boolean;
    passwordError: boolean;
    showPassword: boolean;
    showPassClick: () => void;
    setUsername: ( v: string ) => void;
    setPassword: ( v: string ) => void;
    onLogin: () => void;
    onResetPassword: () => void;
    onNewbieClick: () => void;
    onKeyDown: ( e: React.KeyboardEvent ) => void;
    helpText: string;
    theme: Theme;
}

export const CredentialsForm = ({
    currentView,
    username,
    password,
    usernameError,
    passwordError,
    showPassword,
    showPassClick,
    setUsername,
    setPassword,
    onLogin,
    onResetPassword,
    onKeyDown,
    onNewbieClick,
    helpText,
    theme
}: CredentialsFormProps ) => (
    <CredentialsFormContainer view={ currentView }>
        <form onKeyDown={ onKeyDown }>
            <Box
                sx={{
                    alignItems: 'flex-start',
                    marginLeft: '3rem',
                    marginRight: '3rem',
                    paddingTop: '3rem',
                }}
            >
                <Box className="desktop-em-in">
                    <StandardTextInput
                        variant='standard'
                        label='Email Address'
                        placeholder='Email Address'
                        required
                        inputProps={{
                            'aria-label': 'Email Address'
                        }}
                        size='medium'
                        error={ usernameError }
                        value={ username }
                        onChange={ ( v ) => setUsername( v )}
                        autoComplete="new-password"
                    />
                </Box>
                
                <Box className="desktop-pd-in" paddingTop='2rem'>
                    <StandardTextInput
                        variant='standard'
                        label='Password'
                        placeholder='Password'
                        helpText={ helpText }
                        required
                        inputProps={{
                            'aria-label': 'Password'
                        }}
                        endAdmornment={
                            <PasswdVisibility
                                theme={ theme }
                                isVisible={ showPassword }
                                visibilityClick={ showPassClick }
                            />
                        }
                        size='medium'
                        type={ showPassword ? 'text' : 'password' }
                        error={ passwordError }
                        value={ password }
                        onChange={ ( v ) => setPassword( v )}
                        autoComplete="new-password"
                    />
                </Box>
                
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '3rem',
                    }}
                >
                    <Box>
                        <TextButton onClick={ onResetPassword }>
                            <Typography variant="button">Forgot your password?</Typography>
                        </TextButton>
                        &nbsp;
                        <Typography variant="button">or</Typography>
                        &nbsp;
                        <TextButton onClick={ onNewbieClick }>
                            <Typography variant="button">are you new?</Typography>
                        </TextButton>
                    </Box>
                    <RoundedButton onClick={ onLogin }>
                        <Typography variant="button">Login</Typography>
                    </RoundedButton>
                </Box>

            </Box>
        </form>
    </CredentialsFormContainer>
)