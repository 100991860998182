import React, { useState, useEffect } from "react";
import { LandingForm } from "./Landing";
import { useAppDispatch } from "../../../redux/Store";
import { validatePassword, validateMfaCode, validateEmail } from "../../../utils/commons";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths, AppStatusCodes } from "../../../constants/enums";
import { useMediaWidth } from "../../../hooks";
import { addAlert } from "../../../redux/slicers/AppState";
import { registrationMFA } from "../../../redux/AccessThunks";
import { setAuthID } from "../../../redux/slicers/Access";
import { setPassword } from "../../../redux/AppStateThunks";
import { mapErrorMessages } from "../../../utils/validation";
import { global_EMPTY_UUID } from "../../../constants";
import { set } from "node_modules/cypress/types/lodash";

// Error Maps
const userErrorMap = {
    email: ''
}
const mfaErrorMap = {
    mfaCode: ''
}
const passwordErrorMap = {
    newPasswd: '',
    confirmPasswd: ''
}


export default function RegistrationsJWT() {
    const [ view, setView ] = useState< 'mfa' | 'verify' | 'reset' | 'complete' | 'failure' >( 'mfa' )
    const [ currentStep, setCurrentStep ] = useState< number >( 0 )
    const [ newPassword, setNewPassword ] = useState< string >( '' )
    const [ confirmPassword, setConfirmPassword ] = useState< string >( '' )
    const [ validPassword, setValidPassword ] = useState< boolean >( true )
    const [ inValidMessage, setInValidMessage ] = useState< string >( '' )
    const [ showPassword, setShowPassword ] = useState< boolean >( false )
    const [ showConfirmPassword, setShowConfirmPassword ] = useState< boolean >( false )
    const [ mfaResponse, setMfaResponse ] = useState< number >( 0 )
    // Errors
    const [ helperText, setHelperText ] = useState< string >( '' )
    const [ passwordError, setPasswordError ] = useState< boolean >( false )
    const [ mfaError, setMfaError ] = useState< boolean >( false )

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isTablet = useMediaWidth({ direction: 'down', breakPoint: 'tablet' })
    
    useEffect(() => {
        switch( view ) {
            case 'mfa':
                setCurrentStep( 1 )
                break
            case 'verify':
                setCurrentStep( 1 )
                break
            case 'reset':
                setCurrentStep( 2 )
                break
            case 'complete':
                setCurrentStep( 3 )
                break
            default:
                setCurrentStep( 0 )
        }
    }, [ view ])

    useEffect(() => {
        const criteriaMessage: string = isTablet ? 
            'Criteria: min 10 chars, upper & lowercase, numbers, & special char'
            :
            'Password must include numbers, lower & uppercase, special characters, and minumum 10 characters'
        ;
        const newLength: boolean = newPassword.length >= 10;
        const conLength: boolean = confirmPassword.length >= 10;
        const criteriaNew: boolean = validatePassword( newPassword )
        const criteriaCon: boolean = validatePassword( confirmPassword )
        const strictMatch: boolean = newPassword === confirmPassword

        if ( !newLength ) {
            setValidPassword( true )
            setInValidMessage( '' )
            return;
        }

        if ( !criteriaNew ) {
            setValidPassword( false )
            setInValidMessage( criteriaMessage )
            return
        }  
        if ( criteriaNew ) {
            setValidPassword( true )
            setInValidMessage( '' )
        }
        
        if ( !conLength ) return;
            
        
        if ( !criteriaCon ) {
            setValidPassword( false )
            setInValidMessage( criteriaMessage )
            return
        }  
        if ( criteriaCon ) {
            setValidPassword( true )
            setInValidMessage( '' )
        }

        if ( !strictMatch ) {
            setValidPassword( false )
            setInValidMessage( 'Passwords do not match' )
            return
        }

        setValidPassword( true )
        setInValidMessage( '' )
    },[ newPassword, confirmPassword ])



    async function onSubmitMFA( mfaCode: string ) {
        if ( !validateMfaCode( mfaCode )) {
            setMfaError( true )
            setHelperText( 'Please enter a valid 6-digit code' )
            return
        }
        setMfaError( false )
        setHelperText( '' )

        const { success, code, payload } = await dispatch( registrationMFA({ MFACode: mfaCode })).unwrap()
        if ( success && code === AppStatusCodes.NEW_REG_MFA_SUCCESS_SET_PASSWORD ) {
            dispatch( addAlert({ id: Date.now(), message: 'Password reset approved', type: 'success' }))
            setView( 'reset' )
            setMfaResponse( 200 )
            dispatch( setAuthID( payload ))
            return
        }
        if ( !success && code === AppStatusCodes.MFA_INVALID_CODE ) {
            setMfaError( true )
            dispatch( addAlert({ id: Date.now(), message: 'Invalid code. Please try again.', type: 'warning' }))
            setHelperText( 'Invalid code. Please try again' )
            return
        }
        if ( !success && code === AppStatusCodes.DATA_VALIDATION_JOI ) {
            const errors: any = mapErrorMessages( payload, mfaErrorMap )
            setMfaError( true )
            setHelperText( errors?.mfaCode )
        }
        dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
    }

    async function onSubmitNewPassword() {
        if ( newPassword !== confirmPassword ) {
            setPasswordError( true )
            setHelperText( 'Passwords do not match' )
            return
        }
        if ( !validatePassword( newPassword )) {
            setPasswordError( true )
            setHelperText( 'Password does not meet the complexity criteria' )
            return
        }
        const { success, code, payload } = await dispatch( setPassword({ newPasswd: newPassword, confirmedPasswd: confirmPassword })).unwrap()
        if ( success && code === AppStatusCodes.PASSWORD_RESET_SUCCESS ) {
            setView( 'complete' )
            dispatch( addAlert({ id: Date.now(), message: 'Password reset successful', type: 'success' }))
            return
        }
        if ( !success ) {
            switch( code ) {
                case AppStatusCodes.PASSWORD_COMPLEXITY_FAILURE:
                    setPasswordError( true )
                    setView( 'reset' )
                    setHelperText( 'Password does not meet the complexity criteria' )
                    return
                case AppStatusCodes.PASSWORD_REUSE_DETECTED:
                    setPasswordError( true )
                    setView( 'reset' )
                    setHelperText( 'You have used this password previously. Please choose a new password' )
                    return
                case AppStatusCodes.NEWPASS_MISMATCH:
                    setPasswordError( true )
                    setView( 'reset' )
                    setHelperText( 'Passwords do not match, please re-enter the password' )
                    return
                case AppStatusCodes.DATA_VALIDATION_JOI: {
                    const errors: any = mapErrorMessages( payload, passwordErrorMap )
                    setPasswordError( true )
                    setView( 'reset' )
                    setHelperText( errors?.newPasswd )
                    return
                }
                default:
                    dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
                    setView( 'failure' )
                    return
            }
        }
        dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
        setView( 'failure' )
    }

    function reset() {
        setView( 'mfa' )
        setCurrentStep( 0 )
        setNewPassword( '' )
        setConfirmPassword( '' )
        setValidPassword( true )
        setInValidMessage( '' )
        setShowPassword( false )
        setShowConfirmPassword( false )
        setMfaResponse( 0 )
        setPasswordError( false )
        setHelperText( '' )
        setMfaError( false )
        setHelperText( '' )
        dispatch( setAuthID( global_EMPTY_UUID ))
    }

    const cancelReset = () => {
        reset()
        navigate( RoutePaths.LOGIN, { state: { from: location.pathname }, replace: true } )
    }

    const handleVisibility = ( v: 'new' | 'conf' ) => {
        if ( v === 'new' ) {
            setShowPassword( !showPassword )
        }
        if ( v === 'conf' ) {
            setShowConfirmPassword( !showConfirmPassword )
        }
    }
    
    return (
        <LandingForm
            currentView={ view }
            currentStep={ currentStep }
            helpText={ helperText }
            newPassword={ newPassword }
            setNewPassword={ setNewPassword }
            confirmPassword={ confirmPassword }
            setConfirmPassword={ setConfirmPassword }
            isValid={ validPassword }
            inValidMessage={ inValidMessage }
            showPassword={ showPassword }
            showConfirmPassword={ showConfirmPassword }
            showPasswordClick={ handleVisibility }
            submitMFA={ onSubmitMFA }
            submitNewPassword={ onSubmitNewPassword }
            onCancel={ cancelReset }
            passwordError={ passwordError }
            mfaError={ mfaError }
            onTakeToLogin={ cancelReset }
            mfaResponse={ mfaResponse }
        />
    )
}