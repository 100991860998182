import React from "react"
import { Typography } from "@mui/material"

export const budgetHelpText = ( rawLabel: string ) => {
    const label = rawLabel.toLowerCase()
    
    const category: Array<{ label: string, explaination: React.ReactNode }> = [
        {
            label: 'fuel',
            explaination: <React.Fragment>
                <Typography variant="caption">Mark, Can I please have the help text blurbs?</Typography>
                <br />
                <br />
                <Typography variant="caption">You can make them multilined and stuff</Typography>
            </React.Fragment>
        },
        {
            label: 'registration',
            explaination: <React.Fragment>
                <Typography variant="caption">Mark, oh Mark, can I please get those help text blurbs?</Typography>
            </React.Fragment>
        },
        {
            label: 'insurance',
            explaination: <React.Fragment>
                <Typography variant="caption">...Just waiting on Mark to get them help texts over.</Typography>
            </React.Fragment>
        },
        {
            label: 'maintenance',
            explaination: <React.Fragment>
                <Typography variant="caption">This one is about maintenance...</Typography>
            </React.Fragment>
        },
        {
            label: 'car wash',
            explaination: <React.Fragment>
                <Typography variant="caption">...</Typography>
            </React.Fragment>
        },
        {
            label: 'car wash',
            explaination: <React.Fragment>
                <Typography variant="caption">... -_- still no help texts</Typography>
            </React.Fragment>
        },
        {
            label: 'ctp',
            explaination: <React.Fragment>
                <Typography variant="caption">echo!..........echo</Typography>
            </React.Fragment>
        },
        {
            label: 'other',
            explaination: <React.Fragment>
                <Typography variant="caption">I know he hasn't forgotten...</Typography>
            </React.Fragment>
        },
        {
            label: 'tyres',
            explaination: <React.Fragment>
                <Typography variant="caption">crickets...</Typography>
            </React.Fragment>
        },
        {
            label: 'roadside assistance',
            explaination: <React.Fragment>
                <Typography variant="caption">... It'll be christmas in like a week</Typography>
            </React.Fragment>
        }
 
    ]

    const findItem = category.filter( item => item.label === label )    
    return findItem.length > 0 ? findItem[0].explaination : <Typography variant="caption"></Typography>
}