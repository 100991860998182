import { useNavigate } from 'react-router-dom';
import { 
    Grid,
    Typography,
    Box,
    useTheme,
    Table,
    TableBody,
    TableRow,
    TableCell,
    alpha,
    Card,
    useMediaQuery
} from '@mui/material';
import { BasicButton } from './Button_Basic';
import { RoutePaths } from "../../constants/enums";


export interface IAcountBalanceProps {
    balanceIsCredit: boolean;
    availableBalance: string;
    currentBalance: string;
    pendingTransactions: string;
    fbtLibility: string;
    lndLibility: string;
    finalBalance: string;
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export function AccountBalance({ 
    balanceIsCredit,
    availableBalance,
    currentBalance, 
    pendingTransactions,
    fbtLibility,
    lndLibility,
    finalBalance,
    xsmobile = 4,
    mobile = 5,
    tablet = 12,
    laptop = 12,
    desktop = 12
}: IAcountBalanceProps ) {
    const isMobile = useMediaQuery( useTheme().breakpoints.down( 'tablet' ))
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'
    const navigate = useNavigate()

    function handleBalanceColor( balanceIsCredit: boolean, opacity?: number ) { 
        switch ( balanceIsCredit ) {
            case true:
                return opacity ? alpha( theme.palette.success.main, opacity ) : theme.palette.success.main
            case false:
                return opacity ? alpha( theme.palette.error.main, opacity ) : theme.palette.error.main
            default:
                return opacity ? alpha( theme.palette.info.main, opacity ) : theme.palette.info.main
        }
    }

    return (
        <Grid item
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
            sx={{
                width: '100%'
            }}
        >
            <Card elevation={ 1 }
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexGrow: 1,
                    width: '100%',
                    minWidth: "100%",
                    height: isMobile ? 360 : 323,
                    backgroundColor: isDarkMode ? 
                        theme.palette.overlays.level1 : theme.palette.background.paper
                }}
            >

                <Table sx={{ /*borderRadius: '0.4'*/ }}>
                    <TableBody>
                        <TableRow>
                            
                            <TableCell>
                                <Box sx={{ marginBottom: '1rem' }}>
                                    <Typography variant='h4' >
                                        Balance Breakdown
                                    </Typography>
                                </Box>

                                <Typography variant='tableHeader' color={ () => handleBalanceColor( balanceIsCredit ) }>
                                    Available Balance: { availableBalance }
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
      
                                <BasicButton
                                    variant='text'
                                    text='View Transactions'
                                    size='small'
                                    freezeVariant={ true }
                                    onClickHandler={ () => navigate( RoutePaths.PACKAGE_TRANSACTIONS ) }
                                />

                            </TableCell>

                        </TableRow>
                        <TableRow>

                            <TableCell>
                                <Typography variant='tableHeader'>
                                    Current Balance:
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography variant='tableHeader'>
                                    { currentBalance }
                                </Typography>
                            </TableCell>
                            
                        </TableRow>
                        <TableRow>

                            <TableCell>
                                <Typography variant='tableHeader'>
                                    Pending Transactions:
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography variant='tableHeader'>
                                    - { pendingTransactions }
                                </Typography>
                            </TableCell>

                        </TableRow>
                        <TableRow>

                            <TableCell>
                                <Typography variant='tableHeader'>
                                    Fringe Benefits Tax Liability (FBT):
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography variant='tableHeader'>
                                    - { fbtLibility }
                                </Typography>
                            </TableCell>

                        </TableRow>
                        <TableRow>

                            <TableCell>
                                <Typography variant='tableHeader'>
                                    LCT Non-deductible (LND):
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography variant='tableHeader'>
                                    - { lndLibility }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: handleBalanceColor( balanceIsCredit, 0.15 ), height: '100%' }}>

                            <TableCell>
                                <Typography 
                                    variant='tableHeader' 
                                >
                                   Final Balance (Available Balance):
                                </Typography>
                            </TableCell>
                            <TableCell align='right' >
                                <Typography 
                                    variant='tableHeader' 
                                    color={ () => handleBalanceColor( balanceIsCredit ) }
                                >
                                    { finalBalance }
                                </Typography>
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>

            </Card>
        </Grid>
    )
}