import { Box, InputAdornment, Typography, Theme, IconButton } from "@mui/material";
import { PasswdConfirmationContainer } from "../../../components/styled";
import { StandardTextInput } from "../../../components/UI";
import { RoundedButton } from "../../../components/styled";
import { PasswdVisibility } from "../../../components/UI/PasswdVisibility";


interface FormProps {
    currentView: 'user' | 'mfa' | 'verify' | 'reset' | 'complete' | 'failure';
    error: boolean;
    helpText: string;
    newPassword: string;
    confirmPassword: string;
    setNewPassword: ( v: string ) => void;
    setConfirmPassword: ( v: string ) => void;
    isValid: boolean;
    inValidMessage: string;
    showNewPassword: boolean;
    showConfirmPassword: boolean;
    visibilityClick: ( v: 'new' | 'conf' ) => void;
    onSubmit: () => void;
    onKeyDown: ( e: React.KeyboardEvent ) => void;
    onCancel: () => void;
    theme: Theme
}

export const SetPasswdForm = ({ 
    currentView,
    error,
    helpText,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    isValid,
    inValidMessage,
    showNewPassword,
    showConfirmPassword,
    visibilityClick,
    onSubmit,
    onKeyDown,
    onCancel,
    theme
}: FormProps ) => (
    <PasswdConfirmationContainer view={ currentView }>
        <form onKeyDown={ onKeyDown }>
            <Box
                sx={{
                    alignItems: 'flex-start',
                    marginLeft: '3rem',
                    marginRight: '3rem',
                    paddingTop: '3rem',
                }}
            >

                <Box className="desktop-nwpd-in">
                    <StandardTextInput
                        variant='standard'
                        label='New Password'
                        placeholder='Enter New Password (minimum 10 characters)'
                        required
                        inputProps={{
                            'aria-label': 'New Password',
                            sx: { color: '#1235ce' }
                        }}
                        endAdmornment={
                            <PasswdVisibility
                                showMatchIndicator={ newPassword?.length >= 10 }
                                isMatch={ isValid }
                                theme={ theme }
                                isVisible={ showNewPassword }
                                visibilityClick={ () => visibilityClick( 'new' ) }
                            />  
                        }
                        size='medium'
                        type={ showNewPassword ? 'text' : 'password' }
                        error={ error || !isValid }
                        value={ newPassword }
                        onChange={ ( v ) => setNewPassword( v ) }
                        autoComplete="new-password"
                        helpText={ helpText || newPassword?.length >= 10 ? inValidMessage : '' }
                    />
                </Box>

                <Box className="desktop-conpd-in" paddingTop='2rem'>
                    <StandardTextInput
                        variant='standard'
                        label='Confirm Password'
                        placeholder='Re-enter New Password (minimum 10 characters)'
                        required
                        inputProps={{
                            'aria-label': 'Confirm Password',
                            sx: { color: '#1235ce' }
                        }}
                        endAdmornment={
                            <PasswdVisibility
                                showMatchIndicator={ confirmPassword?.length >= 10 }
                                isMatch={ isValid }
                                theme={ theme }
                                isVisible={ showConfirmPassword }
                                visibilityClick={ () => visibilityClick( 'conf' ) }
                            />  
                        }
                        size='medium'
                        type={ showConfirmPassword ? 'text' : 'password' }
                        error={ error || !isValid }
                        value={ confirmPassword }
                        onChange={ ( v ) => setConfirmPassword( v ) }
                        autoComplete="new-password"
                        helpText={ helpText || confirmPassword?.length >= 10 ? inValidMessage : '' }
                    />
                </Box>

                <Typography variant="caption" sx={{ color: theme.palette.error.main }}>
                    { error && helpText }
                </Typography>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '3rem',
                    }}
                >
                    <RoundedButton onClick={ onCancel }>
                        Cancel
                    </RoundedButton>

                    <RoundedButton onClick={ onSubmit }>
                        Set Password
                    </RoundedButton>
                </Box>

            </Box>
        </form>
    </PasswdConfirmationContainer>
)