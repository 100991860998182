import React, { useState, useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { IReimbursmentSubmission } from '../../../../constants/interfaces';
import { useAppDispatch, useAppSelector } from '../../../../redux/Store';
import { columnBase } from '../../../../constants';
import { submitNewReimbursement, fetchBankAccounts } from '../../../../redux/AccessThunks';
import { addAlert } from '../../../../redux/slicers/AppState';
import { ReimbursementForm } from './ReimbursementForm';
import { validateSubmission } from './SubmissionValidation';
import { DateTime } from 'luxon'; 
import {
    BasicSelectOption,
    FileUploader
} from '../../../../components/UI';


const defaultValues: IReimbursmentSubmission = {
    ContactID: 0,
    SubmittedByID: 0,
    BenefitID: 0,
    BenefitDesc: '',
    Odometer: 0,
    BenefitOperCostTypeID: 0,
    BenefitOperCostName: '',
    PurchaseDate: DateTime.local().toISO(),
    SupplierCompanyName: '',
    IsNoReferenceNumber: false,
    SupplierRefText: '',
    AmountIncTax: '0.00',
    AmountTax: '0.00',
    UserComment: '',
    BankAccountID: 0,
    IsGSTOverride: false,
    FileAttachments: []
}

export interface errorMap {
    leaseSelect: errorDetail,
    operCostSelect: errorDetail,
    bankAccountSelect: errorDetail,
    supplierName: errorDetail,
    purchaseDate: errorDetail,
    refNumber: errorDetail,
    odoReading: errorDetail,
    amountIncTax: errorDetail,
    amountTax: errorDetail,
    comment: errorDetail,
    files: errorDetail
}

interface errorDetail {
    error: boolean,
    message: string
}

export function SubmitNewReimbursement() {
    const { userDetails, registeredBankAccounts, basicPackageDetail } = useAppSelector(( state ) => state.Access );
    const [ bankAccountOptions, setBankAccountOptions ] = useState< Array< BasicSelectOption > >([]);
    const [ submissionSuccess, setSubmissionSuccess ] = useState< boolean >( false );
    const [ reimbursementValues, setReimbursementValues ] = useState< IReimbursmentSubmission >( defaultValues );
    const [ errorMap, setErrorMap ] = useState< errorMap >({
        leaseSelect: { error: false, message: '' },
        operCostSelect: { error: false, message: '' },
        bankAccountSelect: { error: false, message: '' },
        supplierName: { error: false, message: '' },
        purchaseDate: { error: false, message: '' },
        refNumber: { error: false, message: '' },
        odoReading: { error: false, message: '' },
        amountIncTax: { error: false, message: '' },
        amountTax: { error: false, message: '' },
        comment: { error: false, message: '' },
        files: { error: false, message: '' }
    });
    const dispatch = useAppDispatch();
    const isDark: boolean = useTheme().palette.mode === 'dark';

    useEffect(() => {
        if ( registeredBankAccounts.length === 0 && userDetails.ContactID !== 0 ) {
            ( async () => await dispatch( fetchBankAccounts({ ContactID: userDetails.ContactID, ActiveOnly: 1 }) ))();
        }
    }, [ userDetails.ContactID ]);

    useEffect(() => {
        if ( registeredBankAccounts.length > 0 ) {
            let bankOptions: Array< BasicSelectOption > = [];
            registeredBankAccounts.forEach(( acc ) => {
                if ( acc.ApprovalStatusID === 0 ) return;
                bankOptions.push({ 
                    key: acc.AccID,
                    label: `${ acc.AccNickname ? acc.AccNickname : acc.AccFormalName } - BSB: ${ acc.AccBSB } Acc: ${ acc.AccNumber }`,
                    value: acc.AccID 
                });
            });
            setBankAccountOptions( bankOptions );
        }
    }, [ registeredBankAccounts ]);

    // remove ended packages
    const filterPackages = basicPackageDetail.filter(( veh ) => veh.BenefitStatus !== 'Ended' );

    // Map object for package selection
    const packages = filterPackages.map(( veh ) => (
        {
            key: `${ veh.BenefitID } - ${ veh.Rego }`,
            label: `${ veh.VehicleDescription } - (${ veh.BenefitStatus })`,
            value: veh.BenefitID
        }
    ))

    // Benefit Select and Description Setter 
    function handleBenefitSelection( value: any ) {
        let selectedBenefit = basicPackageDetail.filter(( benefit ) => benefit.BenefitID === value );
        setReimbursementValues({ 
            ...reimbursementValues,
            ContactID: userDetails.ContactID, 
            SubmittedByID: userDetails.ContactID, 
            BenefitID: value, 
            BenefitDesc: selectedBenefit[ 0 ].VehicleDescription 
        });
    }

    function resetErrors() {
        setErrorMap({
            leaseSelect: { error: false, message: '' },
            operCostSelect: { error: false, message: '' },
            bankAccountSelect: { error: false, message: '' },
            supplierName: { error: false, message: '' },
            purchaseDate: { error: false, message: '' },
            refNumber: { error: false, message: '' },
            odoReading: { error: false, message: '' },
            amountIncTax: { error: false, message: '' },
            amountTax: { error: false, message: '' },
            comment: { error: false, message: '' },
            files: { error: false, message: '' }
        });
    }

    async function onSubmitReimbursement( reimbursement: IReimbursmentSubmission ) {
        resetErrors();
        const formData: IReimbursmentSubmission = {
            ContactID: userDetails.ContactID,
            SubmittedByID: userDetails.ContactID,
            BenefitID: reimbursement.BenefitID,
            BenefitDesc: reimbursement.BenefitDesc,
            Odometer: reimbursement.Odometer,
            BenefitOperCostTypeID: reimbursement.BenefitOperCostTypeID,
            BenefitOperCostName: reimbursement.BenefitOperCostName,
            PurchaseDate: reimbursement.PurchaseDate,
            SupplierCompanyName: reimbursement.SupplierCompanyName,
            IsNoReferenceNumber: reimbursement.IsNoReferenceNumber,
            SupplierRefText: reimbursement.SupplierRefText,
            AmountIncTax: Number( reimbursement.AmountIncTax ).toFixed( 2 ),
            AmountTax: Number( reimbursement.AmountTax ).toFixed( 2 ),
            UserComment: reimbursement.UserComment,
            BankAccountID: reimbursement.BankAccountID,
            IsGSTOverride: reimbursement.IsGSTOverride,
            FileAttachments: reimbursement.FileAttachments.map( file => ({
                FileName: file.FileName,
                FileData: file.FileData,
                FileSize: file.FileSize,
                FileType: file.FileType
            }))
        };
        //// Validate the submission
        const { isValid, errors } = validateSubmission( reimbursement );

        if ( isValid ) {
            const submitted = await dispatch( submitNewReimbursement( formData ) ).unwrap();
            if ( submitted.success && typeof submitted.payload.NewSubmissionID === 'number' ) {
                setReimbursementValues( defaultValues );
                setSubmissionSuccess( true );
                dispatch( addAlert({ 
                    id: Date.now(),
                    type: 'success',
                    message: `Submission successfully` 
                }));
            } else {
                dispatch( addAlert({ id: Date.now(), message: `Submission failed`, type: 'error' }) );
            }
        } else {
            // Display the errors & handle validation errors
            let newErrors: errorMap = { ...errorMap };
            errors.forEach(( error ) => {
                switch ( error.field ) {
                    case 'leaseSelect':
                        newErrors.leaseSelect = { error: true, message: error.message };
                        break;
                    case 'operCostSelect':
                        newErrors.operCostSelect = { error: true, message: error.message };
                        break;
                    case 'bankAccountSelect':
                        newErrors.bankAccountSelect = { error: true, message: error.message };
                        break;
                    case 'supplierName':
                        newErrors.supplierName = { error: true, message: error.message };
                        break;
                    case 'purchaseDate':
                        newErrors.purchaseDate = { error: true, message: error.message };
                        break;
                    case 'refNumber':
                        newErrors.refNumber = { error: true, message: error.message };
                        break;
                    case 'odoReading':
                        newErrors.odoReading = { error: true, message: error.message };
                        break;
                    case 'amountIncTax':
                        newErrors.amountIncTax = { error: true, message: error.message };
                        break;
                    case 'amountTax':
                        newErrors.amountTax = { error: true, message: error.message };
                        break;
                    case 'comment':
                        newErrors.comment = { error: true, message: error.message };
                        break;
                    case 'files':
                        newErrors.files = { error: true, message: error.message };
                        break;
                    default:
                        dispatch( addAlert({ id: Date.now(), message: `An error occurred`, type: 'error' }) );
                }
            });
            setErrorMap( newErrors );
        }
    }

    const rv: IReimbursmentSubmission = reimbursementValues;
    const unlockForm: boolean = rv.BenefitID === 0 
        || rv.BenefitOperCostTypeID === 0 
        || rv.BankAccountID === 0
    ;
    const unlockSubmit: boolean = rv.BenefitID === 0 
        || rv.BenefitOperCostTypeID === 0 
        || rv.BankAccountID === 0
        || rv.SupplierCompanyName === ''
        || rv.PurchaseDate === ''
        || rv.AmountIncTax === '0.00'
        || rv.AmountIncTax === '0'
        || !rv.AmountIncTax
        || rv.FileAttachments.length === 0
    ;

    const handlekeyPress = ( event: React.KeyboardEvent ) => {
        if ( event.key === 'Enter' && !unlockSubmit ) {
            onSubmitReimbursement( reimbursementValues );
        }
    }

    return (
        <Box className='submit-reimbursement-container'
            sx={{ maxWidth: '100%', minWidth: '100%' }}
        >
            <Grid container
                flexDirection='column'
                justifyContent='center' 
                columns={ columnBase }
                spacing={ 1 }
                p={ 1 }
                width='100%'
                sx={{ display: 'flex', flexDirection: 'row' }}
            >

                <ReimbursementForm
                    newReimbursement={ reimbursementValues }
                    packages={ packages }
                    selectedBenefit={ reimbursementValues.BenefitID }
                    selectedOperCost={ reimbursementValues.BenefitOperCostTypeID }
                    selectedAccount={ reimbursementValues.BankAccountID }
                    bankAccountOptions={ bankAccountOptions }
                    setReimbursementValues={ setReimbursementValues }
                    handleBenefitSelection={ handleBenefitSelection }
                    unlockForm={ unlockForm }
                    unlockSubmit={ unlockSubmit }
                    onKeyDown={ handlekeyPress }
                    mode={ isDark }
                    onSubmit={ () => onSubmitReimbursement( reimbursementValues ) }
                    errorMap={ errorMap }
                    helpText={ '' }
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 8 }
                    laptop={ 5 }
                    desktop={ 6 }
                />

                <FileUploader
                    xsmobile={ 4 }
                    mobile={ 5 }
                    tablet={ 8 }
                    laptop={ 5 }
                    desktop={ 6 }
                    setFileUploads={ ( files ) => setReimbursementValues({ 
                        ...reimbursementValues, 
                        FileAttachments: files.map( file => ({
                            FileName: file.FileName,
                            FileData: file.FileData,
                            FileSize: file.FileSize,
                            FileType: file.FileType
                        })) 
                    })}
                    clearFiles={ submissionSuccess === true }
                    fileError={ errorMap.files.error }
                    helpText={ errorMap.files.message }
                />

            </Grid>
        </Box>
    )
}