import React from "react";
import { Typography, Box, Stack, useTheme } from "@mui/material";
import { LoginFormContainer, SlideContainer, FadeContainer } from "../../../components/styled";
import { SetPasswdForm } from "./SetPasswdForm";
import { StepperBlue, MFAInput, MFALoader } from "../../../components/UI";
import { RegistrationSuccess, RegistrationFailure } from "../ResponseDisplay";

interface PasswdResetProps {
    currentView: 'user' | 'mfa' | 'verify' | 'reset' | 'complete' | 'failure';
    currentStep: number;
    helpText: string;
    newPassword: string;
    setNewPassword: ( v: string ) => void;
    confirmPassword: string;
    setConfirmPassword: ( v: string ) => void;
    isValid: boolean;
    inValidMessage: string;
    showPassword: boolean;
    showConfirmPassword: boolean;
    showPasswordClick: ( v: 'new' | 'conf' ) => void;
    submitMFA: ( v: string ) => void;
    submitNewPassword: () => void;
    onCancel: () => void;
    onTakeToLogin: () => void;
    // Errors
    passwordError: boolean;
    mfaResponse: number;    
    mfaError: boolean;
}

export const LandingForm = ({ 
    currentView,
    currentStep,
    helpText,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    isValid,
    inValidMessage,
    submitMFA,
    submitNewPassword,
    onCancel,
    showPassword,
    showConfirmPassword,
    showPasswordClick,
    onTakeToLogin,
    passwordError,
    mfaResponse,
    mfaError
}: PasswdResetProps ) => {
    const theme = useTheme();
    const colors = theme.palette;
    const steps: Array< string > = [ 'Email', 'Multi-Factor Authentication', 'New Password' ]
    const inputView: 'credentials' | 'mfa' | 'complete' = currentView === 'user' ? 
        'credentials' : currentView === 'mfa' ? 
        'mfa' : 'complete'
    ;
    const loaderView: 'credentials' | 'mfa' | 'complete' = currentView === 'user' ?
        'credentials' : currentView === 'mfa' ?
        'mfa' : currentView === 'verify' ? 'complete' : 'mfa'
    ;
    const handlekeyPress = ( event: React.KeyboardEvent ) => {
        if ( event.key === 'Enter' && currentView === 'reset' ) {
           submitNewPassword()
        }
    }

    return (
        <LoginFormContainer className="desktop-passwd-reset">
            <Stack sx={{ width: '100%', height: '100%' }}>
                
                <FadeContainer>
                    <Typography variant="h3">
                        Welcome to <span style={{ color: colors.primary.main }}>AutoDash</span>
                    </Typography>
                    
                    <Box
                        style={{
                            alignContent: 'flex-start',
                            width: '100%',
                            paddingTop: '2rem'
                        }}
                    >
                        <Typography variant="h4">Effortless access, anywhere, anytime</Typography>
                    </Box>
                </FadeContainer>

                <StepperBlue steps={ steps } activeStep={ currentStep } viewport="desktop" />

                <SlideContainer>

                    <MFAInput
                        length={ 6 }
                        view={ inputView }
                        error={ mfaError }
                        helpText={ helpText }
                        onCompletion={ ( v ) => submitMFA( v ) }
                    />

                    <MFALoader
                        minMilliSeconds={ 1000 }
                        mfaResponse={ mfaResponse }
                        currentView={ loaderView }
                    />

                    <SetPasswdForm
                        newPassword={ newPassword }
                        setNewPassword={ ( v ) => setNewPassword( v ) }
                        confirmPassword={ confirmPassword }
                        setConfirmPassword={ ( v ) => setConfirmPassword( v ) }
                        isValid={ isValid }
                        inValidMessage={ inValidMessage }
                        currentView={ currentView }
                        error={ passwordError }
                        helpText={ helpText }
                        showNewPassword={ showPassword }
                        showConfirmPassword={ showConfirmPassword }
                        visibilityClick={ showPasswordClick }
                        onSubmit={ submitNewPassword }
                        onKeyDown={ handlekeyPress }
                        onCancel={ onCancel }
                        theme={ theme }
                    />

                    <RegistrationSuccess
                        theme={ theme }
                        currentView={ currentView }
                        onClickHandler={ onTakeToLogin }
                    />

                    <RegistrationFailure
                        theme={ theme }
                        currentView={ currentView }
                        onClickHandler={ onTakeToLogin }
                    />

                </SlideContainer>
                
            </Stack>
        </LoginFormContainer>
    )
}