
export enum AppStatusCodes {
    SUCCESS = 0,
    SUCCESS_MODIFIED_DATA = 10,
    MFA_REQUIRED = 2000,
    FAILED_PASSWORD = 2001,
    USER_NOT_FOUND = 2002,
    MFA_INVALID_CODE = 2003,
    MFA_VERIFICATION_FAILED = 2004,
    MFA_NO_RECORD_FOUND = 2005,
    MFA_MAX_ATTEMPTS_EXCEEDED = 2006,
    MISSING_DATA = 2007,
    AUTHENTICATED = 9000,
    USER_LOCKED_OUT = 9001,
    DUPLICATE_REJECTED = 9002,
    VALIDATION_FAILED = 9003,
    NO_RECORDS_FOUND = 9004,
    OB_DATA_VAL_FAILED = 9005,
    OB_DATA_VAL_REMOVED_INVALID = 9006,
    DATA_VALIDATION_JOI = 9007,
    STEP_UP_REQUIRED = 9008,
    STEP_UP_APPROVED = 9009,
    PASSWORD_RESET_APPROVED = 9010,
    NEWPASS_MISMATCH = 9011,
    PASSWORD_TOO_SHORT = 9012,
    PASSWORD_TOO_LONG = 9013,
    PASSWORD_COMPLEXITY_FAILURE = 9014,
    PASSWORD_RESET_UNAUTHORIZED = 9015,
    PASSWORD_REUSE_DETECTED = 9016,
    PASSWORD_RESET_SUCCESS = 9017,
    VID_VERIFICATION_REQUIRED = 9018,
    VID_INVALID_CODE = 9019,
    VID_EXPIRED = 9020,
    SERVER_INIT_STEPUP = 9021,
    VT_FOUND_MALICIOUS = 9022,
    VT_FOUND_SUSPICIOUS = 9023,
    VT_FOUND_UNDETECTED = 9024,
    VT_SCAN_PASSED = 9025,
    VT_SCAN_FAILED = 9026,
    VT_SCAN_HIGH_TIMEOUTS = 9027,
    USER_KNOWN_BY_OTHER_EMAIL = 9028,
    USER_REGISTRATION_EXISTS = 9029,
    NO_ACTIVE_BENEFITS = 9030,
    NO_CONTACT_FOUND = 9031,
    MULTIPULE_CONTACTS_FOUND = 9032,
    NO_INTERNAL_REGISTRATION_FOUND = 9033,
    PHONE_LAST_SIX_DIGIT_MISMATCH = 9034,
    API_KEY_VERIFICATION_FAILED = 9035,
    JWT_VERIFICATION_REQUIRED = 10500,
    JWT_VERIFICATION_FAILED = 10501,
    JWT_VERIFICATION_SUCCESS = 10502,
    JWT_VERIFICATION_INVALID = 10503,
    JWT_VERIFICATION_ATTEMPTS_EXCEEDED = 10504,
    JWT_EXPIRED = 10505,
    JWT_BLACKLISTED = 10506,
    JWT_REVOKED = 10507,
    JWT_VERIFICATION_ERROR = 10508,
    NEW_REG_MFA_SUCCESS_SET_PASSWORD = 10509,
    EMAIL_MISMATCH = 10510,
    NEW_USER_INACTIVE_NO_CREDS = 10511,
    NO_MOBILE_PHONE_ASSOCIATED_WITH_USER = 10512
}

export enum ErrorCodes {
    HTTP_STATUS = 1002,
    AUTHENTICATION = 1003,
    SYS_SMS_FAILED = 1004,
    SYS_EMAIL_FAILED = 1005,
    NO_EMAIL_RECIPIENT = 1006,
    MFA_CODE_GEN_FALIED = 1007,
    MFA_HASH_GEN_FAILED = 1008,
    MFA_VAR_GEN_FAILED = 1009,
    MFA_DB_INSERT_FAILED = 1009,
    DATABASE_PROCEDURE_FAILED = 1010,
    SUB_FUNCTION_FAILED = 1011,
    UNKOWN_FUNCTION_ERROR = 1012,
    MISSING_FUNCTION_PARAMS = 1013,
    INVALID_FUNCTION_PARAMS = 1014,
    ARGON_HASH_CREATION_FAILED = 1015,
    NETWORK_ERROR = 1016,
    BAD_REQUEST = 1017,
    ACTION_ABORTED = 1018,
    PASSWORD_UPDATE_FAILED = 1019,
    VID_CODE_GEN_FAILED = 1020,
    VID_DB_INSERT_FAILED = 1021,
    VT_FILE_SCAN_REQUEST_FAILED = 1022,
    JWT_GEN_FAILED = 1023,
    JWT_INVALID = 1024,
    JWT_EXPIRED = 1025,
    INVALID_API_KEY = 1026
}

export enum HttpMethods {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
    PATCH = 'PATCH',
	DELETE = 'DELETE'
}

export enum HttpStatusCodes {
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NO_CONTENT = 204,
    FOUND = 302,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    REQUEST_TIMEOUT = 408,
    CONFLICT = 409,
    GONE = 410,
    PAYLOAD_TOO_LARGE = 413,
    UNSUPPORTED_MEDIA_TYPE = 415,
    INTERNAL_SERVER_ERROR = 500,
    SERVICE_UNAVAILABLE = 503
}

export enum RoutePaths {
    // Public Routes
    ROOT = '/',
    LOGIN = '/',
    LOGOUT = '/',
    REGISTER = 'newbie',
    PASSWORD_RESET = 'um_I_Forgetty',
    JWT_REGISTRATION_LANDING = 'newbie_confirmation',
    JWT_PASSWD_LANDING = 'unforgetty_confirmation',
    ROUTE_404 = '*',
    // Authenticated Routes
    AUTHENTICATED_ROOT = '/account/',
    PROFILE = '/account/profile',
    PACKAGE = '/account/package',
    PACKAGE_TRANSACTIONS = '/account/package/transactions',
    REIMBURSEMENT_HISTORY = '/account/package/reimbursementHistory',
    REIMBURSEMENTS = '/account/reimbursements',
    DOCUMENTS = '/account/documents',
    FAQS = '/account/faqs',
    CONTACT_US = '/account/contactUs',
    PREFERENCES = '/account/preferences',
    NOTIFICATIONS = '/account/notifications',
}

export enum MFAMethod {
    SMS = 1000,
    EMAIL = 1010,
    GOOGLE = 1020,
    MICROSOFT = 1030
}

export enum MFA_Reason {
    LOGIN = 'LGN',
    SENSITIVE_INFO_CHANGE = 'SIC',
    PASSWORD_RESET = 'PWR',
    EM_OR_MB_VERIFICATION = 'EMV'
}

export enum AppEndpoints {
    // Auth
    LOGIN = "/auth/login",
    LOGOUT = "/auth/logout",
    MFA_VERIFICATION = "/auth/verify",
    STEPUP_AUTH_REQUET = "/auth/stepup/:reason",
    STEPUP_AUTH_VERIFY = "/auth/stepup/verify",
    // Contact
    PROFILE = "/contact/profile",
    PREF_NAME = "/contact/prefName",    
    EMAIL = "/contact/email",
    EMAIL_DELETE = "/contact/email/:ContactID/:EmailID",
    PHONE = "/contact/phone",
    PHONE_DELETE = "/contact/phone/:ContactID/:PhoneID",
    ADDRESS = "/contact/address",
    EMV_VERIFICATION = "/contact/verify/:EmvCode",
    // Benefits
    BASIC_INFO = "/benefit/basic",
    BALANCE_HISTORY = "/benefit/balanceHistory",
    PACKAGE_DATA = "/benefit/package",
    BENEFIT_ACCOUNT = "/benefit/benefitAccount",
    TRANSACTION_HISTORY = "/benefit/transactionHistory",
    // Financial
    REIMBURSEMENT_HISTORY = "/financial/reimbursement/:ContactID",
    REIMBURSEMENT_SUBMIT = "/financial/reimbursement",
    BANK_ACCOUNTS = "/financial/bankAccounts/:ContactID/:ActiveOnly",
    BANK_ACCOUNT_ADD = "/financial/bankAccounts/add",
    BANK_ACCOUNT_DELETE = "/financial/bankAccounts/:ContactID/:AccID",
    FILES = "/financial/files/:ContactID",
    // System
    CONTACT_REQUEST = "/system/requestContact",
    REQUEST_PASSWORD_RESET = "/system/passwordReset",
    VERIFY_PASSWORD_RESET = "/system/resetApproval",
    SET_NEW_PASSWORD = "/system/_reset",
    PREFERENCES = "/system/preferences"
}

export enum Action {
    LOGIN = 'LOGIN',
    LOGIN_MFA_VERIFICATION = 'LOGIN_MFA_VERIFICATION',
    LOGOUT = 'LOGOUT',
    FETCH_PROFILE = 'FETCH_PROFILE',
    FETCH_BASIC_PACKAGE_INFO = 'FETCH_BASIC_PACKAGE_INFO',
    FETCH_FULL_PACKAGE_INFO = 'FETCH_FULL_PACKAGE_INFO',
    FETCH_ACCOUNT_DETAIL = 'FETCH_ACCOUNT_DETAIL',
    FETCH_BALANCE_HISTORY = 'FETCH_BALANCE_HISTORY',
    FETCH_DOCUMENTS = 'FETCH_DOCUMENTS',
    FETCH_REIMBURSEMENT_HISTORY = 'FETCH_REIMBURSEMENT_HISTORY',
    FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS',
    FETCH_TRANSACTION_HISTORY = 'FETCH_TRANSACTION_HISTORY',
    FETCH_BUDGET_DATA = 'FETCH_BUDGET_DATA',
    SUBMIT_NEW_REIMBURSEMENT = 'SUBMIT_NEW_REIMBURSEMENT',
    ADD_NEW_PHONE = 'ADD_NEW_PHONE',
    ADD_NEW_EMAIL = 'ADD_NEW_EMAIL',
    ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS',
    ADD_NEW_BANK_ACCOUNT = 'ADD_NEW_BANK_ACCOUNT',
    UPDATE_PREFERRED_NAME = 'UPDATE_PREFERRED_NAME',
    UPDATE_PHONE = 'UPDATE_PHONE',
    UPDATE_EMAIL = 'UPDATE_EMAIL',
    UPDATE_ADDRESS = 'UPDATE_ADDRESS',
    UPDATE_PREFERENCES = 'UPDATE_PREFERENCES',
    DELETE_PHONE = 'DELETE_PHONE',
    DELETE_EMAIL = 'DELETE_EMAIL',
    DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT',
    INITIATE_STEPUP_AUTH = 'INITIATE_STEPUP_AUTH',
    VERIFY_STEPUP_AUTH = 'STEPUP_AUTH_VERFICATION',
    LODGE_CONTACT_REQUEST = 'LODGE_CONTACT_REQUEST',
    REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
    VERIFY_PASSWORD_RESET_MFA = 'VERIFY_PASSWORD_RESET',
    SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
    VERIFY_VID = 'VERIFY_VID',
    FILE_DOWNLOAD = 'FILE_DOWNLOAD',
    NEW_REGISTRATION = 'NEW_REGISTRATION',
    REGISTRATION_MFA = 'REGISTRATION_MFA'
}