import React from 'react';
import { Theme, useTheme } from '@mui/material';
import { EmailType, PhoneType, RoutePaths, ReimbOperCostType, MFAMethod } from '../../constants/enums';

export * from './Charts';
export * from './DataGrid';
export * from './Addresses';

export const handlePackageStatusColor = ( status: string ) => {
    const theme: Theme = useTheme();
    switch ( status ) {
        case 'Active':
            return theme.palette.success.main
        case 'Ended':
            return theme.palette.error.main
        case 'Under Reconciliation':
            return theme.palette.warning.main
        default:
            return theme.palette.info.main
    }
}

export const emailTypeIDConverter = ( typeID: EmailType ) => {
    switch ( typeID ) {
        case EmailType.PERSONAL:
            return 'Personal Email'
        case EmailType.BUSINESS:
            return 'Work Email'
        case EmailType.SECONDARY:
            return 'Secondary Email'
        case EmailType.OTHER:
            return 'Other Email'
        default:
            return typeID
    }
}

export const phoneTypeIDConverter = ( typeID: PhoneType ) => {
    switch ( typeID ) {
        case PhoneType.HOME:
            return 'Home Phone'
        case PhoneType.BUSINESS:
            return 'Work Phone'
        case PhoneType.MOBILE:
            return 'Mobile Phone'
        case PhoneType.BIZ_DIRECT:
            return 'Business Direct'
        case PhoneType.FAX:
            return 'Fax'
        default:
            return typeID
    }
}


export function sumObjectsByKey( array: any[], key: string ): number {
    return array.reduce(( acc, obj ) => acc + obj[key], 0);
}

export const currencyFormatter = new Intl.NumberFormat( 'en-US', { style: 'currency', currency: 'USD'  })

export const operCostIDConverter = ( id: ReimbOperCostType ) => {
    switch ( id ) {
        case ReimbOperCostType.FUEL:
            return 'Fuel'
        case ReimbOperCostType.MAINTENANCE:
            return 'Maintenance'
        case ReimbOperCostType.TYRES:
            return 'Tyres'
        case ReimbOperCostType.INSURANCE:
            return 'Insurance'
        case ReimbOperCostType.MANAGEMENT_FEE:
            return 'Management Fee'
        case ReimbOperCostType.REGISTRATION:
            return 'Registration'
        case ReimbOperCostType.CTP:
            return 'Compulsory Third Party Insurance'
        case ReimbOperCostType.FLEET_CARD:
            return 'Fleet Card'
        case ReimbOperCostType.OTHER:
            return 'Other'
        case ReimbOperCostType.PAYROLL_ALLOCATION:
            return 'Payroll Allocation'
        case ReimbOperCostType.LEASE_PAYMENT:
            return 'Lease Payment'
        case ReimbOperCostType.GST_ON_CONTRIBUTION:
            return 'GST on Contribution'
        case ReimbOperCostType.RESIDUAL_PROTECTION_COVER:
            return 'Residual Protection Cover'
        case ReimbOperCostType.ROADSIDE_ASSISTANCE:
            return 'Roadside Assistance'
        case ReimbOperCostType.ACCIDENT_MANAGEMENT:
            return 'Accident Management'
        case ReimbOperCostType.TOLLS:
            return 'Toll Costs'
        case ReimbOperCostType.FEES:
            return 'Fees'
        case ReimbOperCostType.EXTENDED_WARRANTY:
            return 'Extended Warranty'
        case ReimbOperCostType.CAR_WASH:
            return 'Car Wash'
        case ReimbOperCostType.CARBON_OFFSET:
            return 'Carbon Offset'
        case ReimbOperCostType.LEASE_PROTECTION_INSURANCE:
            return 'Lease Protection Insurance'
        case ReimbOperCostType.VEHICLE_ACQUISITION:
            return 'Vehicle Acquisition'
        case ReimbOperCostType.CONTINGENCY:
            return 'Contingency'
        case ReimbOperCostType.GAP_INSURANCE:
            return 'GAP Insurance'
        case ReimbOperCostType.FINANCE_DOCUMENTATION:
            return 'Finance Documentation'
        case ReimbOperCostType.HOME_CHARGING_REIMBURSEMENT:
            return 'Home Charging Reimbursement'
        default:
            return id
    }
}

export const EllipsisWrapper = ({ children }: { children: React.ReactNode } ) => (
    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        { children }
    </div>
)

export const formatPhoneByType = ( phoneNumber: string, typeID: PhoneType ) => {
    switch ( typeID ) {
        case PhoneType.MOBILE:
            return mobileFormatter( phoneNumber )
        case PhoneType.HOME:
            return landlineFormatter( phoneNumber )
        case PhoneType.BUSINESS:
            return landlineFormatter( phoneNumber )
        case PhoneType.BIZ_DIRECT:
            return landlineFormatter( phoneNumber )
        case PhoneType.FAX:
            return landlineFormatter( phoneNumber )
        default:
            return phoneNumber
    }
}

const mobileFormatter = ( phoneNumber: string ) => {
    if ( phoneNumber.length === 10 ) {
        const partOne = phoneNumber.slice( 0, 4 );
        const partTwo = phoneNumber.slice( 4, 7 );
        const partThree = phoneNumber.slice( 7, 10 );
    
        return `${ partOne } ${ partTwo } ${ partThree }`
    }
    return phoneNumber
}

const landlineFormatter = ( phoneNumber: string ) => {
    if ( phoneNumber.length === 10 ) {
        const partOne = phoneNumber.slice( 0, 2 );
        const partTwo = phoneNumber.slice( 2, 6 );
        const partThree = phoneNumber.slice( 6, 10 );

        return `(${ partOne }) ${ partTwo } ${ partThree }`
    }
    if ( phoneNumber.length === 8 ) {
        const partOne = phoneNumber.slice( 0, 4 );
        const partTwo = phoneNumber.slice( 4, 8 );

        return `${ partOne } ${ partTwo }`
    }
    return phoneNumber
}

export function switchMFA( mfa: MFAMethod ): string {
    switch ( mfa ) {
        case MFAMethod.SMS:
            return 'SMS'
        case MFAMethod.EMAIL:
            return 'Email'
        case MFAMethod.GOOGLE:
            return 'Google Authenticator'
        case MFAMethod.MICROSOFT:
            return 'Microsoft Authenticator'
        default:
            return 'Please set your MFA method'
    }
}

export function mfaMethodText( method: MFAMethod ): string {
    switch ( method ) {
        case MFAMethod.SMS:
            return 'mobile';
        case MFAMethod.EMAIL:
            return 'email';
        case MFAMethod.GOOGLE:
            return 'Google Authenticator';
        case MFAMethod.MICROSOFT:
            return 'Microsoft Authenticator';
        default:
            return '';
    }
}

function stringToHash( string: string ): number {
    let hash = 0;
    if ( string.length === 0 ) return hash;
    for ( let i = 0; i < string.length; i++ ) {
        hash = string.charCodeAt( i ) + (( hash << 5 ) - hash);
    }
    return hash;
}

function intToHSL( hash: number, isDark: boolean ): { hsl: string, textColor: string } {
    const hue = hash % 360;
    const saturation = isDark ? 60 + ( hash % 30 ) : 40 + ( hash % 40 );
    const lightness = isDark ? 70 - ( hash % 20 ) : 30 + ( hash % 20 );
    let textColor;
    if ( isDark ) {
        textColor = lightness > 50 ? "#000000" : "#FFFFFF";
    } else { 
        textColor = lightness > 50 ?  "#000000" : "#FFFFFF";
    }
    const hsl = `hsl(${ hue }, ${ saturation }%, ${ lightness }%)`;
    return { hsl, textColor };
}

export function stringToColor( string: string, isDark: boolean ): { hsl: string, textColor: string } {
    const hash = stringToHash( string );
    return intToHSL( hash, isDark );
}
